import React, { useContext } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Popup, PopupSection, Button, Infobox } from '@flixbus/honeycomb-react';
import { Icon, IconDeleteSolid } from '@flixbus/honeycomb-icons-react';
import { useApi, useTemplate } from '../../../hooks';
import DisplayTemplate from '../../DisplayTemplate';
import { TranslateContext } from '../../../system/Translate';

export default function DeleteSection() {
    const { id } = useParams();
    const { translate } = useContext(TranslateContext);
    const history = useHistory();

    const { data: templates } = useTemplate({
        refetchOnMount: false,
    });
    const templatesToDelete = templates ? templates.filter((template) => template.id === id) : [];
    const isAssignedCase = templatesToDelete.some((template) => Boolean(template.automatedCase));
    const [deleted, deleteTemplate] = useApi('DELETE', `/template/${id}`);

    return (
        <Popup onOverlayClick={() => history.goBack()} active>
            <PopupSection type="icon">
                <Icon InlineIcon={IconDeleteSolid} size={8} />
            </PopupSection>
            <PopupSection type="title">{translate('delete-template-info')}</PopupSection>
            <PopupSection type="content" extraClasses="cn-popup-content--left">
                {templatesToDelete
                    .filter((template) => template.id === id)
                    .map((template) => (
                        <DisplayTemplate key={`${template.id}-${template.updatedAt}`} template={template} />
                    ))}
                {deleted.error && `Not deleted ${deleted.error.toString()}`}
                {deleted.data && !deleted.pending ? <Redirect to="/" /> : null}
                {isAssignedCase && (
                    <Infobox appearance="danger">{translate('delete-template.assign-warning')}</Infobox>
                )}
            </PopupSection>
            <PopupSection type="actions">
                {isAssignedCase === false ? (
                    <Button
                        loading={deleted.pending}
                        appearance="primary"
                        onClick={() => {
                            deleteTemplate();
                        }}
                    >
                        {translate('delete')}
                    </Button>
                ) : null}
                <Button appearance="secondary" onClick={() => history.goBack()}>
                    {translate('cancel')}
                </Button>
            </PopupSection>
        </Popup>
    );
}
