import React from 'react';
import { FormRow, Grid, GridCol, Skeleton } from '@flixbus/honeycomb-react';

export default function FormSkeleton(props) {
    return (
        <>
            <Grid>
                <GridCol size={6}>
                    <Grid>
                        <GridCol>
                            <FormRow>
                                <Skeleton height="md" />
                            </FormRow>
                        </GridCol>
                        <GridCol>
                            <FormRow>
                                <Skeleton height="md" />
                            </FormRow>
                        </GridCol>
                    </Grid>
                    <Grid>
                        <GridCol>
                            <FormRow>
                                <Skeleton height="lg" />
                            </FormRow>
                            <FormRow>
                                <Skeleton Elem="span" height="lg" />
                                <Skeleton Elem="span" height="lg" />
                            </FormRow>
                        </GridCol>
                    </Grid>
                </GridCol>
            </Grid>
        </>
    );
}
