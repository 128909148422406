import { PublicClientApplication } from '@azure/msal-browser';

/**
 * config for auth microsoft
 * @constant
 * @name msalConfig
 */

export const clientId = 'bc5d1e0a-da54-455d-8ff5-fcc30bb4a0d6';
export const uniqueId = 'd8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5';

export const msalConfig = {
    auth: {
        clientId,
        authority: `https://login.microsoftonline.com/${uniqueId}`,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
    system: {
        allowRedirectInIframe: true,
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const scopes = ['api://bc5d1e0a-da54-455d-8ff5-fcc30bb4a0d6/user'];

export const msalPopupRequest = {
    scopes,
    redirectUri: window.location.origin,
};

export const msalSilentRequest = {
    scopes,
};

export const customerNotifierV2Application = {
    scopes: [
        'https://customer-notifier2-ops-services-public.ew1p1.k8s.flix.tech/API.ReadWrite',
    ],
    authority: msalConfig.auth.authority,
};
