import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const CloudFiltersContext = createContext();
export const CLOUD_FILTERS_STORAGE_KEY = 'cloud_filters';

export const CloudFiltersProvider = ({ children }) => {
    const [filters, setFilters] = useState({});
    const history = useHistory();
    const location = useLocation();
    const isInitialLoad = useRef(true);


    const parseFiltersFromURL = useCallback(()=> {
        const urlFilters = {};
        new URLSearchParams(location.search).forEach((value, key) => {
            if (!urlFilters[key]) {
                urlFilters[key] = new Set();
            }
            urlFilters[key].add(value);
        });
        return urlFilters;
    }, [location.search])

    const updateUrl = useCallback((currentFilters) => {
        const searchParams = new URLSearchParams();
        for (const [key, valueSet] of Object.entries(currentFilters)) {
            valueSet.forEach((value) => {
                searchParams.append(key, value);
            });
        }
        history.replace({ search: searchParams.toString() });
    }, [history]);

    useEffect(() => {
        if(location.pathname === '/') {
            const storableFilters = Object.entries(filters).reduce((acc, [key, value]) => {
                return {
                    ...acc,
                    [key]: Array.from(value),
                }
            }, {})

            sessionStorage.setItem(CLOUD_FILTERS_STORAGE_KEY, JSON.stringify(storableFilters));
        }
    }, [filters, location])

    useEffect(() => {
        if (isInitialLoad.current && location.pathname === '/') {
            const urlFilters = parseFiltersFromURL();

            if(!urlFilters.shopGroup) {
                urlFilters.shopGroup = new Set(['FLIX']);
                updateUrl(urlFilters);
            }
            setFilters(urlFilters);
            isInitialLoad.current = false;
        }
    }, [updateUrl, parseFiltersFromURL, location]);

    const addFilter = (name, value, isSingleValue = false) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };

            if (isSingleValue) {
                updatedFilters[name] = new Set([value]);
            } else {
                if (!updatedFilters[name]) {
                    updatedFilters[name] = new Set();
                }
                updatedFilters[name].add(value);
            }

            if(isSingleValue && !value) {
                removeFilter(name, value);
            } else {
                updateUrl(updatedFilters);
            }

            return updatedFilters;
        });
    };

    const removeFilter = (name, value) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            if (updatedFilters[name]) {
                updatedFilters[name].delete(value);
                if (updatedFilters[name].size === 0) {
                    delete updatedFilters[name];
                }
            }
            updateUrl(updatedFilters);
            return updatedFilters;
        });
    };

    const removeFilterCategory = (categoryName) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            if (updatedFilters[categoryName]) {
                delete updatedFilters[categoryName];
            }
            updateUrl(updatedFilters);
            return updatedFilters;
        });
    };

    const resetFilters = () => {
        setFilters({})
        updateUrl({});
    }

    return (
        <CloudFiltersContext.Provider value={{ filters, addFilter, removeFilter, resetFilters, removeFilterCategory }}>
            {children}
        </CloudFiltersContext.Provider>
    );
};

CloudFiltersProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};
CloudFiltersProvider.defaultProps = {
    children: null,
};

export const useCloudFilters = () => useContext(CloudFiltersContext);