import defaultSupportedPlaceholders from './defaultSupportedPlaceholders';

export function validate(object, rules, automatedSupportedPlaceholders) {
    const validation = rules.reduce((acc, rule) => {
        const { checker, field, message } = rule;
        const result = [...acc];
        if (typeof checker === 'function') {
            const placeholders = automatedSupportedPlaceholders?.length ? automatedSupportedPlaceholders : undefined;
            if (checker(object[field], object, placeholders) === false) {
                result.push({ field, message });
            }
        } else if (checker.test(object[field].toString()) === false) {
            result.push({ field, message });
        }
        return result;
    }, []);

    return (
        validation.length === 0 ||
        validation.reduce((acc, vl) => {
            const res = { ...acc };
            if (res[vl.field]) {
                res[vl.field].push(vl.message);
            } else {
                res[vl.field] = [vl.message];
            }
            return res;
        }, {})
    );
}

export function bracketsValidation(value) {
    if (value === '' || value === undefined) {
        return true;
    }
    const openMatch = value.match(/\[\[/gim);
    const closeMatch = value.match(/\]\]/gim);
    if (openMatch === null && closeMatch === null) {
        return true;
    }
    if (openMatch !== null && closeMatch !== null) {
        return openMatch.length === closeMatch.length;
    }
    return false;
}

export function placeholdersValidation(value, object, supportedPlaceholders = defaultSupportedPlaceholders) {
    const placeholderPattern = /\[\[([^[\]]+)\]\]/g;
    let match;

    while ((match = placeholderPattern.exec(value)) !== null) {
        if (!supportedPlaceholders.includes(match[1])) {
            return false;
        }
    }

    return true;
}
