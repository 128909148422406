import axios from 'axios';
import { useState, useEffect } from 'react';
import { CUSTOMER_NOTIFIER_V2_SCOPE, getToken } from '../auth';

const CUSTOMER_NOTIFIER_V2_GET_PCR_URL = 'https://customer-notifier.bruteforce-prod.flixtech.io/api/custom-pcr';

const useGetOrdersData = (dataToken) => {
    const [data, setData] = useState({ orderIds: [], rideId: '' });

    useEffect(() => {
        const fetchDataWithAuthToken = async () => {
            const receivedToken = await getToken(CUSTOMER_NOTIFIER_V2_SCOPE);

            if (receivedToken) {
                const ordersData = await axios.get(`${CUSTOMER_NOTIFIER_V2_GET_PCR_URL}/${dataToken}` , {
                    headers: {
                        'Authorization': `Bearer ${receivedToken}`,
                    },
                });
                setData(ordersData.data);
            }
        };

        fetchDataWithAuthToken();
    }, [dataToken]);

    return {
        orderIds: data?.orders,
        rideId: data?.rideId,

    };
}

export default useGetOrdersData;