const langsList = {
    en: {
        code: 'en',
        shortTitle: 'En',
        title: 'English',
        flagCode: 'gb',
        countryName: 'Great Britain',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/us.min.svg',
    },
    de: {
        code: 'de',
        shortTitle: 'De',
        title: 'Deutsch',
        flagCode: 'de',
        countryName: 'Germany',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/de.min.svg',
    },
    tr: {
        code: 'tr',
        shortTitle: 'Tr',
        title: 'Türk',
        flagCode: 'tr',
        countryName: 'Turkey',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/tr.min.svg',
    },
};

export default langsList;
