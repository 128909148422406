import { useState, useEffect, useCallback } from 'react';
import { useApi } from '.';

/**
 * Hook to validate case if it's available to assign
 * @function useCaseValidate
 * @param {string} channel - email | sms | push
 * @param {string} shopGroup - FLIX | GH | ...
 * @returns {array} - [response, request(case)]
 */
export default function useCaseValidate(channel, shopGroup) {
    const [caseToCheck, setCase] = useState('');
    const getUrl = useCallback(
        function () {
            return `/template/check-automated-case/${caseToCheck}/${channel}/${shopGroup}`;
        },
        [caseToCheck, channel, shopGroup]
    );
    const [response, request] = useApi('GET', getUrl);

    useEffect(() => {
        if (caseToCheck !== '') {
            request();
        }
    }, [caseToCheck, request]);

    function requestValidation(validateCase) {
        setCase(validateCase);
    }
    return [response, requestValidation];
}
