import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Popup, PopupSection, Button, Fineprint, Link } from '@flixbus/honeycomb-react';
import { Icon, IconInfoSolid, IconInfo } from '@flixbus/honeycomb-icons-react';
import { ConfigContext } from '../../../context';
import { TranslateContext } from '../../../system/Translate';

export default function PlaceholdersPopup(props) {
    const { translate } = useContext(TranslateContext);
    const { automatedCase } = props;
    const [isActive, setActive] = useState(false);
    const config = useContext(ConfigContext);
    const placeholders = config ? 
        config.automatedCases.reduce((acc, item) => {
            if (item.name === automatedCase) {
                return item.placeholders;
            }
            return acc;
        }, []) : [];
    function toggleActive() {
        setActive(!isActive);
    }

    return (
        <>
            <Button appearance="link" onClick={toggleActive}>
                <Icon InlineIcon={IconInfoSolid} />
                {translate('placeholders')}
            </Button>
            <Popup active={isActive} onOverlayClick={() => setActive(false)}>
                <PopupSection type="icon">
                    <Icon InlineIcon={IconInfo} size={8} />
                </PopupSection>
                <PopupSection type="title">{translate('supported-placeholders')}</PopupSection>
                <PopupSection type="content">
                    {placeholders.map((placeholder) => (
                        <div key={placeholder}>{placeholder}</div>
                    ))}
                    {placeholders.length === 0 && (
                        <Fineprint>
                            <Link target="_blank" href="https://wiki.flix.tech/pages/viewpage.action?pageId=117903606">
                                {translate('supported-placeholders-wiki')}
                            </Link>
                        </Fineprint>
                    )}
                </PopupSection>
                <PopupSection type="actions">
                    <Button appearance="secondary" type="button" onClick={toggleActive}>
                        {translate('ok')}
                    </Button>
                </PopupSection>
            </Popup>
        </>
    );
}
PlaceholdersPopup.propTypes = { automatedCase: PropTypes.string };
PlaceholdersPopup.defaultProps = { automatedCase: '' };
