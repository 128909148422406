import React, { useContext } from 'react';
import {
    Flag,
    Header,
    HeaderBrand,
    HeaderSubNavigation,
    HeaderUserWidget,
    HeaderWidgets,
    LanguageSwitcher,
    LanguageSwitcherPopup,
    LanguageSwitcherPopupItem,
    LanguageSwitcherPopupRegion,
    LanguageSwitcherToggle,
    NavItem,
} from '@flixbus/honeycomb-react';
import { Icon, IconProfile } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../system/Translate';
// eslint-disable-next-line import/no-cycle
import { getUser, msalInstance } from '../../auth';
import { USER_RULES, USER_ACTION_TITLES, USER_ROLE_TITLES } from '../../auth/userRules';
import { clientId, uniqueId } from '../../auth/msalConfig';

export default function AppHeader() {
    const { translate } = useContext(TranslateContext);
    const { langsList, language, setLanguage } = useContext(TranslateContext);
    const activeLanguage = langsList[language];
    const user = getUser();
    const profileName = user?.profile.name;
    const roles = user?.profile.roles || [];

    const getUserMenuRoleItem = (role) => {
        return (
            <>
                <strong>
                    <Icon InlineIcon={IconProfile} />
                    {USER_ROLE_TITLES[role] || role}
                </strong>
                {USER_RULES[role]?.length > 0 ? (
                    getUserMenuRoleRules(role)
                ) : (
                    <div>{translate('user-menu.readonly-access')}</div>
                )}
            </>
        )
    }

    const getUserMenuRoleRules = (role) => {
        return (
            <>
                <div>{translate('user-menu.actionslist-title')}</div>
                <ul>
                    {USER_RULES[role].map((rule) => (
                        <li key={`${role}-${rule}`}>
                            {USER_ACTION_TITLES[rule] || rule}
                        </li>
                    ))}
                </ul>
            </>
        )
    }

    const userMenu = () => {
        return roles.reduce((menuItems, role) => {
            const menuItem = {
                content: getUserMenuRoleItem(role),
                href: '/',
                onClick: (e) => e.preventDefault(),
                key: role,
            };
            return [...menuItems, menuItem];
        }, []);
    };

    const handleLogOutClick = (instance) => {
        const homeAccountId = `${uniqueId}.${clientId}`;
        const logoutRequest = {
            account: instance.getAccountByHomeId(homeAccountId),
            mainWindowRedirectUri: window.location.origin,
            postLogoutRedirectUri: '/',
        };
        instance.logoutPopup(logoutRequest);
    }

    const handleLangItemClick = (code) => {
        setLanguage(code);
    };

    return (
        <Header extraClasses="cn-header" fixed={false}>
            <HeaderBrand
                alt="Customer notifier"
                href="/"
                src="https://cdn-cf.cms.flixbus.com/drupal-assets/logos/flixbus.png"
            />
            <HeaderWidgets style={{ display: 'flex', alignItems: 'center' }} noResponsiveRendering>
                <LanguageSwitcher style={{ marginRight: '15px' }}>
                    <LanguageSwitcherToggle
                        flag={<Flag countryCode={activeLanguage.flagCode} countryName={activeLanguage.countryName} />}
                    >
                        {activeLanguage.title}
                    </LanguageSwitcherToggle>

                    <LanguageSwitcherPopup aria-label="Language selector." closeLabel="Close language selector.">
                        <LanguageSwitcherPopupRegion title="Select a language">
                            {Object.keys(langsList).map((key) => { 
                                const { flagCode, countryName, code, title } = langsList[key]; 
                            
                                return ( 
                                    <LanguageSwitcherPopupItem 
                                        active={activeLanguage.code === code} 
                                        key={code} 
                                        onClick={() => handleLangItemClick(code)} 
                                        flag={<Flag countryCode={flagCode} countryName={countryName} />} 
                                        lang={code} 
                                    > 
                                        {title} 
                                    </LanguageSwitcherPopupItem> 
                            
                                ) 
                            })} 
                        </LanguageSwitcherPopupRegion>
                    </LanguageSwitcherPopup>
                </LanguageSwitcher>
                {user && (
                    <HeaderUserWidget
                        subMenu={(
                            <HeaderSubNavigation id="user-menu">
                                {userMenu().map((item) => (
                                    <NavItem key={item.key} onClick={item.onClick}>
                                        {item.content}
                                    </NavItem>
                                ))}
                                <NavItem
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLogOutClick(msalInstance);
                                    }}
                                >
                                    Logout
                                </NavItem>
                            </HeaderSubNavigation>
                        )}
                    >
                        {profileName}
                    </HeaderUserWidget>
                )}

            </HeaderWidgets>
        </Header>
    );
}
