import { useState, useEffect } from 'react';

const defaultState = {
    subjects: [],
    contents: [],
    automatedCases: [],
    users: [],
};

const useGroupedData = (data) => {
    const [groupedData, setGroupedData] = useState(defaultState);

    useEffect(() => {
        let idleCallbackId;

        const processGroupedData = () => {
            if (!Array.isArray(data) || data.length === 0) {
                return defaultState;
            }

            const subjectSet = new Set();
            const contentSet = new Set();
            const automatedCaseSet = new Set();
            const userSet = new Set();

            data.forEach((item) => {
                item.subject && subjectSet.add(item.subject);
                item.content && contentSet.add(item.content);
                item.updatedBy && userSet.add(item.updatedBy);
                item.automatedCase && automatedCaseSet.add(item.automatedCase);
            });

            return {
                subjects: Array.from(subjectSet),
                contents: Array.from(contentSet),
                automatedCases: Array.from(automatedCaseSet),
                users: Array.from(userSet),
            };
        };

        idleCallbackId = requestIdleCallback(() => {
            const processedData = processGroupedData();
            setGroupedData(processedData);
        });

        return () => {
            if (idleCallbackId) {
                cancelIdleCallback(idleCallbackId);
            }
        };
    }, [data]);

    return groupedData;
};

export default useGroupedData;