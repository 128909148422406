import axios from 'axios';
import { useEffect, useState } from 'react';
import { CUSTOMER_NOTIFIER_V2_SCOPE, getToken } from '../auth';

const CUSTOMER_NOTIFIER_V2_GET_TEMPLATES_URL = 'https://customer-notifier.bruteforce-prod.flixtech.io/api/templates';

const useGetWhatsAppTemplates = () => {
    const [responseData, setResponseData] = useState([]);
    useEffect(() => {
        const requestTemplates = async () => {
            const authToken = await getToken(CUSTOMER_NOTIFIER_V2_SCOPE);
            const response = await axios.get(CUSTOMER_NOTIFIER_V2_GET_TEMPLATES_URL, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setResponseData(response.data);
        }

        requestTemplates();
    }, [])

    return {
        templates: responseData,
    }
}

export default useGetWhatsAppTemplates;