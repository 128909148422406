import { bracketsValidation, placeholdersValidation } from '../../helpers';

export default [
    {
        field: 'subject',
        checker: (value, object) => object.channel === 'sms' || value !== '',
        message: 'Subject is required',
    },
    {
        field: 'content',
        checker: (value) => value !== '',
        message: 'Content is required',
    },
    {
        field: 'subject',
        checker: bracketsValidation,
        message: 'Placeholders brackets does not match',
    },
    {
        field: 'content',
        checker: bracketsValidation,
        message: 'Placeholders brackets does not match',
    },
    {
        field: 'content',
        checker: placeholdersValidation,
        message: 'Placeholder is not supported',
    },
    {
        field: 'subject',
        checker: placeholdersValidation,
        message: 'Placeholder is not supported',
    },
];

