import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Fineprint } from '@flixbus/honeycomb-react';
import useUserCan from '../../auth/useUserCan';

import './CkEditor.scss';

export default function DraftEditor({ label, content, error, onChange, activeLang }) {
    const isUserCanEditTemplate = useUserCan('edit_template');

    return (
        <div className={error ? 'cn-template-has-error' : ''}>
            {/* use raw styles for label
                created feature request for standalone label
                https://jira.mfb.io/browse/HIPP-237
            */}
            <div className="flix-input">
                <label className="flix-label flix-input__label" htmlFor="message">
                    {label}
                </label>
                <CKEditor
                    disabled={!isUserCanEditTemplate}
                    id="message"
                    className="cn-ck-editor"
                    editor={ClassicEditor}
                    data={content}
                    config={{
                        toolbar: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            'blockQuote',
                            'undo',
                            'redo',
                        ],
                        language: activeLang,
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        if (typeof onChange === 'function') {
                            onChange({ ...event, data }, editor);
                        }
                    }}
                />
                {error && <Fineprint className="cn-template-error">{error}</Fineprint>}
            </div>
        </div>
    );
}

DraftEditor.propTypes = {
    content: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    activeLang: PropTypes.string,
};

DraftEditor.defaultProps = {
    content: '',
    label: 'Label',
    error: null,
    onChange: () => {},
    activeLang: '',
};
