import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataTable, DataTableHead, DataTableRow, DataTableSorter, Tooltip } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconExpand,
    IconCollapse,
    IconDocument,
    IconNewsletter,
    IconChatAltSolid,
    IconWhatsapp,
} from '@flixbus/honeycomb-icons-react';
import TemplateActions from './TemplateActions';
import { TranslateContext } from '../../system/Translate';
import dayjs from '../../day';

import './TemplatesList.scss';

const channelIcons = {
    'email': IconNewsletter,
    'sms': IconChatAltSolid,
    'whatsapp': IconWhatsapp,
}

export default function TemplatesList({ data }) {
    const { translate } = useContext(TranslateContext);

    const [expandedRows, setExpandedRows] = useState({});
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    const [sortedTemplates, setSortedTemplates] = useState([]);

    useEffect(() => {
        setSortedTemplates(data);
    }, [data])

    const onExpand = (id) => {
        if(expandedRows[id]) {
            const expandedRowsCopy = { ...expandedRows };
            delete expandedRowsCopy[id];

            setExpandedRows(expandedRowsCopy);
            return;
        }
        setExpandedRows({ ...expandedRows, [id]: true });
    };

    const sortByAlphabet = (sorting, property) => {
        const sortedData = [...data];

        sortedData.sort((a, b) => {
            const valueA = a[property] ?? '';
            const valueB = b[property] ?? '';

            if (valueA === '' && valueB === '') return 0;
            if (valueA === '') return 1;
            if (valueB === '') return -1;

            if (sorting === 'ascending') {
                return valueA.localeCompare(valueB,undefined,{ numeric: true, sensitivity: 'base' });
            } if (sorting === 'descending') {
                return valueB.localeCompare(valueA,undefined,{ numeric: true, sensitivity: 'base' });
            }

            return valueA.localeCompare(valueB,undefined,{ numeric: true, sensitivity: 'base' });
        });

        setSortedTemplates(sortedData);
    };

    const sortByDate = (sorting) => {
        const sortedData = [...data];

        sortedData.sort((a, b) => {
            const dateA = dayjs(a.updatedAt);
            const dateB = dayjs(b.updatedAt);

            if (sorting === 'ascending') {
                return dateA.diff(dateB);
            } if (sorting === 'descending') {
                return dateB.diff(dateA);
            }

            return dateA.diff(dateB);
        });

        setSortedTemplates(sortedData);
    };

    const handleExpandAllRowsClick = () => {
        if(isAllExpanded) {
            setExpandedRows({});
        } else {
            setExpandedRows(
                sortedTemplates.reduce((acc, curr) => {
                    return {
                        ...acc,
                        [curr.id]: true,
                    }
                }, {})
            );
        }

        setIsAllExpanded((prevVal) => !prevVal);
    }

    const headers = (
        <DataTableHead>
            <td style={{ cursor: 'pointer' }} onClick={handleExpandAllRowsClick}>
                {!isAllExpanded ? (
                    <Icon InlineIcon={IconExpand} />
                ) : (
                    <Icon InlineIcon={IconCollapse} />
                )}

            </td>
            <DataTableSorter
                aria-label="Sort templates by subject"
                onSortingChange={(sorting) => sortByAlphabet(sorting, 'subject')}
            >
                {translate('subject')}
            </DataTableSorter>
            <DataTableSorter
                aria-label="Sort templates by type"
                onSortingChange={(sorting) => sortByAlphabet(sorting, 'channel')}
            >
                {translate('channel')}
            </DataTableSorter>
            <DataTableSorter
                aria-label="Sort templates by automated case"
                onSortingChange={(sorting) => sortByAlphabet(sorting, 'automatedCase')}
            >
                {translate('automated-case')}
            </DataTableSorter>
            <DataTableSorter
                aria-label="Sort templates by content"
                onSortingChange={(sorting) => sortByAlphabet(sorting, 'content')}
            >
                {translate('template-content')}
            </DataTableSorter>
            <DataTableSorter
                aria-label="Sort templates by user"
                onSortingChange={(sorting) => sortByAlphabet(sorting, 'updatedBy')}
            >
                {translate('user')}
            </DataTableSorter>
            <DataTableSorter aria-label="Sort templates by date" onSortingChange={sortByDate}>
                {translate('updated')}
            </DataTableSorter>
            <td>{translate('actions')}</td>
        </DataTableHead>
    )

    const rows = sortedTemplates.map((item) => {
        const {
            id,
            subject,
            content,
            updatedAt,
            updatedBy,
            automatedCase,
            channel,
            shopGroup,
        } = item;
        const updatedDate = Intl.DateTimeFormat('default', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        }).format(new Date(updatedAt));

        const channelIcon = channelIcons[channel];
        const expanded = expandedRows[id] ? ' expanded' : '';

        return (
            <DataTableRow key={id+shopGroup} extraClasses="cn-template__row">
                <th className={`cn-template-expand${expanded}`}>
                    <Icon
                        InlineIcon={expanded ? IconCollapse : IconExpand}
                        extraClasses="cn-template-expand-collapse"
                        onClick={() => onExpand(id)}
                    />
                </th>
                <td className={`cn-template-subject${expanded}`}>
                    {automatedCase && (
                        <Tooltip id={`automated-case-tooltip-${id}`} content={automatedCase} openOnHover>
                            <Icon InlineIcon={IconDocument} title={automatedCase} />
                        </Tooltip>
                    )}
                    {subject}
                </td>
                <td className={`cn-template-desc${expanded}`}>
                    <Icon InlineIcon={channelIcon} size={4} />
                </td>
                <td className={`cn-template-case${expanded}`} title={automatedCase}>
                    {automatedCase || ''}
                </td>
                <td className={`cn-template-desc${expanded}`} title={content}>
                    {content}
                </td>
                <td className={`cn-template-user${expanded}`}>{updatedBy}</td>
                <td className={`cn-template-created${expanded}`}>{updatedDate}</td>
                <td className={`cn-template-actions${expanded}`}>
                    <TemplateActions id={id} shopGroup={shopGroup} />
                </td>
            </DataTableRow>
        );
    });

    return data.length ? (
        <DataTable
            small
            headers={headers}
            rows={rows}
            scrollable="sticky-header"
            extraClasses="cn-templates-list"
        >
            {rows}
        </DataTable>
    ) : (
        <div className="not-found">{translate('no-items-found')}</div>
    );
}
TemplatesList.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            subject: PropTypes.string,
        })
    ).isRequired,
};
