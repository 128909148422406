import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Popup, PopupSection, Button } from '@flixbus/honeycomb-react';
import { Icon, IconEyeSolid } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../system/Translate';

export default function PreviewPopup(props) {
    const { content } = props;
    const { translate } = useContext(TranslateContext);
    const [isActive, setActive] = useState(false);
    function toggleActive() {
        setActive(!isActive);
    }
    return (
        <>
            <Button appearance="link" onClick={toggleActive}>
                <Icon InlineIcon={IconEyeSolid} />
                {translate('html-preview')}
            </Button>
            <Popup active={isActive} onOverlayClick={() => setActive(false)}>
                <PopupSection type="icon">
                    <Icon InlineIcon={IconEyeSolid} size={8} />
                </PopupSection>
                <PopupSection type="title">{translate('html-preview')}</PopupSection>
                <PopupSection type="content" className="nc-popup-content--left">
                    <SyntaxHighlighter language="html" wrapLongLines>
                        {content}
                    </SyntaxHighlighter>
                </PopupSection>
                <PopupSection type="actions">
                    <Button appearance="secondary" type="button" onClick={toggleActive}>
                        {translate('ok')}
                    </Button>
                </PopupSection>
            </Popup>
        </>
    );
}
PreviewPopup.propTypes = { content: PropTypes.string };
PreviewPopup.defaultProps = { content: '' };
