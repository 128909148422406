import React, { useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import enLocale from 'dayjs/locale/en';
import deLocale from 'dayjs/locale/de';
import trLocale from 'dayjs/locale/tr';

import TranslateContext from './TranslateContext';
import langsList from '../langsList';
import localStorage from '../localStorage';

import en from '../en.json';
import de from '../de.json';
import tr from '../tr.json';


const DEFAULT_LNG = 'en';
const DAYJS_LOCALES = { en: enLocale, de: deLocale, tr: trLocale };
const storage = localStorage;

export default function TranslateProvider({ children }) {
    const [translations] = useState({
        en,
        de,
        tr,
    });

    const [language, setLanguage] = useState(
        storage.getItem(storage.LANGUAGE) || DEFAULT_LNG
    );

    const onLanguageSet = (value) => {
        storage.setItem(storage.LANGUAGE, value);
        setLanguage(value);
        dayjs.locale(DAYJS_LOCALES[language]);
    };

    const translate = (key, param) => {
        const regExp = /{(.*?)}/gi;
        let message = translations[language][key];

        // replace the translation with the passed param
        if (param && message) {
            if (typeof param === 'object') {
                message = message.replace(
                    regExp,
                    (match, substr) => param[substr] || match
                );
            } else {
                message = message.replace(regExp, param);
            }
        }

        return message || `<${key.match(/\.?(\w*)$/i)[1]}>`;
    };

    const providerValue = {
        langsList,
        language,
        setLanguage: onLanguageSet,
        translate,
    };

    return (
        <TranslateContext.Provider value={providerValue}>
            {children}
        </TranslateContext.Provider>
    );
}

TranslateProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
