export default [
    'booking_number',
    'first_name',
    'last_name',
    'line_code',
    'where_is_my_bus_link',
    'arrival_stop',
    'arrival_date',
    'arrival_time',
    'departure_delay',
    'departure_stop',
    'departure_date',
    'departure_time',
    'ride_departure_stop',
    'ride_departure_date',
    'ride_departure_time',
    'ride_arrival_stop',
    'ride_arrival_date',
    'ride_arrival_time',
]