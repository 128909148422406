import { useState } from 'react';
import axios from 'axios';
import { CUSTOMER_NOTIFIER_V2_SCOPE, getToken } from '../auth';

const CUSTOMER_NOTIFIER_V2_SEND_TEMPLATE_URL = 'https://customer-notifier.bruteforce-prod.flixtech.io/api/custom-pcr/send';

const useSendWhatsappTemplate = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [responseData, setResponseData] = useState(null);

    const sendTemplate = async ({ dataToken, templateId }) => {
        setLoading(true);
        setError(null);

        try {
            const authToken = await getToken(CUSTOMER_NOTIFIER_V2_SCOPE);
            const response = await axios.post(CUSTOMER_NOTIFIER_V2_SEND_TEMPLATE_URL, {
                templateId,
                customPcrId: dataToken,
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setResponseData(response.data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return {
        sendTemplate,
        loading,
        error,
        response: responseData,
    };
};

export default useSendWhatsappTemplate;