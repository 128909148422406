import { useApi } from '../../../hooks';

const useDeleteTemplate = (templateId) => {
    const [{ data, error, pending }, deleteTemplate] = useApi('DELETE', `/template/${templateId}`);

    return {
        data,
        error,
        pending,
        deleteTemplate,
    }
}

export default useDeleteTemplate;