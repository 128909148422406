import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import { MainWrapper, ThemeWrapper } from '@flixbus/honeycomb-react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.css';
import App from './components/App';
import { NotificationSystem } from './components/Notification';
import { msalInstance, withMsalAuth } from './auth';
import reactQueryConfig from './reactQueryConfig';
import { TranslateProvider } from './system/Translate';

const Protected = withMsalAuth(() => <App />);

const queryClient = new QueryClient(reactQueryConfig);

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Router>
                <MsalProvider instance={msalInstance}>
                    <MainWrapper>
                        <ThemeWrapper>
                            <NotificationSystem>
                                <TranslateProvider>
                                    <Protected />
                                </TranslateProvider>
                            </NotificationSystem>
                        </ThemeWrapper>
                    </MainWrapper>
                </MsalProvider>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
