import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Box, Grid, GridCol, Heading, Text, Button } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft } from '@flixbus/honeycomb-icons-react';
import { rollbar } from '../Rollbar';

export default class ErrorComponent extends Component {
    constructor() {
        super();
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, errorText: error.toString() };
    }

    componentDidCatch(error, errorInfo) {
        rollbar.error(error, errorInfo);
    }

    resetError = () => {
        this.setState({ hasError: false });
    };

    render() {
        const { children } = this.props;
        const { hasError, errorText } = this.state;
        return hasError ? (
            <Grid>
                <GridCol size={2} push={1}>
                    <Button appearance="link" onClick={this.resetError}>
                        <Icon InlineIcon={IconArrowLeft} />
                        Back to App
                    </Button>
                </GridCol>
                <GridCol size={6}>
                    <Box>
                        <Heading size={1}>Sorry, error happens.</Heading>
                        <Text>We already know about this issue. Don&#39;t worry.</Text>
                        <Accordion title="Technical details">
                            <Text>{errorText}</Text>
                        </Accordion>
                    </Box>
                </GridCol>
            </Grid>
        ) : (
            children
        );
    }
}

ErrorComponent.propTypes = { children: PropTypes.node.isRequired };
