import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormRow, Select, SelectOption, Button, Popup, PopupSection, Link } from '@flixbus/honeycomb-react';
import { Icon, IconCrossedSolid } from '@flixbus/honeycomb-icons-react';
import { ConfigContext } from '../../../context';
import { useCaseValidate, usePatchTemplate } from '../../../hooks';
import { TranslateContext } from '../../../system/Translate';
import useUserCan from '../../../auth/useUserCan';

export default function CaseSelector(
    { assignedCase, channel, shopGroup, id, onAssign, selectedPlaceholders, onCaseSelect, translationPlaceholders }
) {
    const { translate } = useContext(TranslateContext);
    const [value, setValue] = useState('');
    const [isPopup, setPopupState] = useState(false);
    const [responseUpdate, updateTemplate, resetResponseUpdate] = usePatchTemplate(id, shopGroup);
    const { data: updatedData, error: updatedError, pending: updatedPending } = responseUpdate;
    const [validateResponse, validate] = useCaseValidate(channel, shopGroup);
    const { data, pending } = validateResponse;
    const [placeholders, setPlaceholders] = useState([]);
    const [allowedPlaceholders, setAllowedPlaceholders] = useState(new Set());
    const [placeholdersError, setPlaceholdersError] = useState('');
    const [translationPlaceholdersError, setTranslationPlaceholdersError] = useState('');

    const isUserCanEditAutoCase = useUserCan('edit_automation_case');

    const config = useContext(ConfigContext);
    const cases = config ? 
        config.automatedCases.map((item) => {
            return {
                value: item.name,
                displayValue: item.name === assignedCase ? 
                    `${item.name} ${translate('assigned-case')}` : item.name,
            };
        }) : [];

    useEffect(() => {
        selectedPlaceholders?.length && setPlaceholders(
            selectedPlaceholders.split(',')
        )
    }, [selectedPlaceholders])

    useEffect(() => {
        const newAllowedPlaceholders = config
            ? config.automatedCases.reduce((acc, item) => {
                if (item.name === value) {
                    return item.placeholders;
                }
                return acc;
            }, [])
            : [];

        setAllowedPlaceholders(new Set(newAllowedPlaceholders));
    }, [value, config]);

    // placeholders validation
    useEffect(() => {
        if(allowedPlaceholders.size) {
            const allPlaceholdersValid = placeholders.every((placeholder) => allowedPlaceholders.has(placeholder));

            if (allPlaceholdersValid) {
                setPlaceholdersError('');
            } else {
                setPlaceholdersError(translate('placeholders_invalid'));
            }

            const restrictedKeys = [];
            for (const [key, values] of Object.entries(translationPlaceholders)) {
                for (const placeholderValue of values) {
                    if (!allowedPlaceholders.has(placeholderValue)) {
                        restrictedKeys.push(key);
                        break;
                    } else {
                        setTranslationPlaceholdersError('');
                    }
                }
            }

            if (restrictedKeys.length) {
                setTranslationPlaceholdersError(
                    ` ${restrictedKeys.join(', ')}`
                );
            }
        }

    }, [allowedPlaceholders, translationPlaceholders, placeholders, translate])

    // set value from the assignedCase prop update
    useEffect(() => {
        setValue(assignedCase);
    }, [assignedCase]);

    // call callback after case was assigned
    useEffect(() => {
        if (updatedData !== null && updatedData.automatedCase !== assignedCase) {
            onAssign({
                automatedCase: updatedData.automatedCase,
            });
            resetResponseUpdate();
        }
    }, [assignedCase, onAssign, resetResponseUpdate, updatedData]);

    // control error popup
    useEffect(() => {
        if (updatedError !== false) {
            setPopupState(true);
        }
    }, [setPopupState, updatedError]);

    // validate and set case after selection
    function onChange(e) {
        const { target } = e;
        if (target.value !== assignedCase) {
            validate(target.value);
        }
        setValue(target.value);
        onCaseSelect(e);
    }

    function isAvailable() {
        return data === null ? false : data.available;
    }

    // helper to enable or disable button
    function isAssignEnable() {
        return value !== assignedCase && isAvailable();
    }

    // provide infoError text based on data state
    function infoError() {
        const message = translate('already-assigned-template');
        if (value === assignedCase) {
            return null;
        }
        if (data === null) {
            return null;
        }
        if (data.available === false) {
            return message;
        }

        return null;
    }

    // call assignment request
    function assignCase(automatedCase) {
        updateTemplate({
            automatedCase,
        });
    }

    return (
        <>
            <FormRow>
                <Select
                    label={translate('automated-cases')}
                    placeholder={translate('not-assigned')}
                    id="automated_cases"
                    name="automatedCases"
                    onChange={onChange}
                    value={value}
                    autofill="off"
                    info={pending ? `${translate('validating')}...` : null}
                    disabled={pending || !isUserCanEditAutoCase}
                    infoError={infoError()}
                    valid={
                        translationPlaceholdersError ||
                        placeholdersError ||
                        data === null ||
                        value === assignedCase ? null : isAvailable()
                    }
                >
                    {cases.map((item) => {
                        return (
                            <SelectOption key={item.value} value={item.value}>
                                {item.displayValue}
                            </SelectOption>
                        );
                    })}
                </Select>
                {data && data.available === false && data.template && (
                    <div className="flix-space-xs-top">
                        <Link href={`/edit/${data.template.id}/${data.template.shopGroup}`}>
                            {data.template.content}
                        </Link>
                    </div>
                )}
                {placeholdersError && (
                    <p className="cn-template__placeholders-error">
                        {placeholdersError}
                        {' '}
                        {translate('allowed_placeholders_error')}
                        {' '}
                        {Array.from(allowedPlaceholders).join(', ')}
                    </p>
                )}
                {translationPlaceholdersError && (
                    <p className="cn-template__placeholders-error">
                        {translate('translation_placeholders_invalid')}
                        {translationPlaceholdersError}
                    </p>
                )}
            </FormRow>
            <FormRow>
                <Button
                    appearance="secondary"
                    type="button"
                    disabled={
                        !isAssignEnable() || !isUserCanEditAutoCase || placeholdersError || translationPlaceholdersError
                    }
                    onClick={() => {
                        assignCase(value);
                    }}
                    loading={updatedPending}
                >
                    {translate('assign')}
                </Button>
                {!assignedCase || updatedPending ? null : (
                    <Button
                        appearance="secondary"
                        type="button"
                        disabled={!assignedCase}
                        onClick={() => {
                            assignCase('');
                        }}
                    >
                        {translate('remove-assignment')}
                    </Button>
                )}
            </FormRow>

            <Popup active={isPopup}>
                <PopupSection type="icon">
                    <Icon InlineIcon={IconCrossedSolid} size={8} />
                </PopupSection>
                <PopupSection type="title">{translate('case-was-not-assigned')}</PopupSection>
                <PopupSection type="content">
                    <pre>{updatedError.toString()}</pre>
                </PopupSection>
                <PopupSection type="actions">
                    <Button
                        appearance="secondary"
                        onClick={() => {
                            setPopupState(false);
                        }}
                    >
                        {translate('close')}
                    </Button>
                </PopupSection>
            </Popup>
        </>
    );
}

CaseSelector.propTypes = {
    id: PropTypes.string.isRequired,
    /** case that already assigned */
    assignedCase: PropTypes.string,
    /** notification channel that use this template */
    channel: PropTypes.string.isRequired,
    shopGroup: PropTypes.string.isRequired,
    /** callback will be called after case was assigned successfully  */
    onAssign: PropTypes.func,
    selectedPlaceholders: PropTypes.string,
    onCaseSelect: PropTypes.func,
    translationPlaceholders: PropTypes.object,
};
CaseSelector.defaultProps = {
    onAssign: () => {},
    assignedCase: '',
    selectedPlaceholders: '',
    onCaseSelect: () => {},
    translationPlaceholders: {},
};
