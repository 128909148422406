import React, { useEffect } from 'react';
import { useApi } from '../../hooks';
import ConfigContext from './context';

export default function withConfigProvider(Component) {
    return function WithConfigProvider(props) {
        const [response, request] = useApi('GET', '/app/config');
        useEffect(() => {
            request();
        }, [request]);
        return (
            <ConfigContext.Provider value={response.data && response.data.config}>
                <Component {...props} />
            </ConfigContext.Provider>
        );
    };
}
