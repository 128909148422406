import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { customerNotifierV2Application, msalInstance, msalSilentRequest } from './msalConfig';
// eslint-disable-next-line import/no-cycle
import withMsalAuth from './withMaslAuth';

export { withMsalAuth, msalInstance };

export const CUSTOMER_NOTIFIER_V2_SCOPE = 'customer_notifier_v2';

// scopes for different jwt tokens for another APIs
const scopes = {
    default: msalSilentRequest,
    [CUSTOMER_NOTIFIER_V2_SCOPE]: customerNotifierV2Application,
};

export function getToken(authScope = 'default') {
    return new Promise((resolve, reject) => {
        const scope = scopes[authScope];
        msalInstance
            .acquireTokenSilent({
                ...scope,
                account: msalInstance.getAllAccounts()[0],
            })
            .then((response) => {
                resolve(response.accessToken);
            })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    return msalInstance
                        .acquireTokenPopup({
                            ...scope,
                            account: msalInstance.getAllAccounts()[0],
                        })
                        .then((response) => {
                            resolve(response.accessToken);
                        });
                }
                return e;
            })
            .catch((e) => reject(new Error(`Auth fail with error: ${e}`)));
    });
}

export function getUser() {
    const accounts = msalInstance.getAllAccounts();
    if (accounts[0]) {
        return {
            profile: accounts[0].idTokenClaims,
            userName: accounts[0].username,
        };
    }
    return null;
}
