import React from 'react';
import { Route } from 'react-router-dom';
import { PageContainer } from '@flixbus/honeycomb-react';
import AppHeader from '../Header';
import CreateForm from '../CreateForm';
import { HomeSection, EditSection, DeleteSection, SendWhatsAppTemplateSection } from './sections';
import ErrorComponent from '../ErrorComponent';
import { CloudFiltersProvider } from './context/CloudFiltersContext';

import './App.scss';


function App() {
    return (
        <>
            <AppHeader />
            <PageContainer extraClasses="cn-container">
                <ErrorComponent>
                    <CloudFiltersProvider>
                        <Route path="/" exact component={HomeSection} />
                        <Route path="/create/:channel/:shopGroup?" exact component={CreateForm} />
                        <Route path="/edit/:id/:shopGroup?" exact component={EditSection} />
                        <Route path="/delete/:id" exact component={DeleteSection} />
                        <Route path="/send-whatsapp-template" exact component={SendWhatsAppTemplateSection} />
                    </CloudFiltersProvider>
                </ErrorComponent>
            </PageContainer>
        </>
    );
}

export default App;
