import Rollbar from 'rollbar';
import { getUser } from '../../auth';

const rollbar = new Rollbar({
    enabled: process.env.REACT_APP_ENV === undefined && process.env.NODE_ENV === 'production',
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN || null,
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    payload: {
        environment: 'production[prod]',
        person: {
            name: getUser() && getUser().profile && getUser().profile.name,
        },
    },
    transform: (payload) => {
        let alterPayload = { ...payload };
        try {
            alterPayload.body.trace.exception.class = `[Template-manager] ${alterPayload.body.trace.exception.class}`;
        } catch {
            console.log('payload.body.trace.exception.message, not changed');
        }
        return alterPayload;
    },
});

export default rollbar;
