import React, {useEffect, useState, useCallback, useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Grid, GridCol } from '@flixbus/honeycomb-react';
import { useApi, useUpdateTemplate, useLanguages } from '../../hooks';
import { LanguageSwitcher, TextEditor, FormSkeleton, CaseSelector } from './components';
import { validate } from '../../helpers';
import validationRules from './validationRules';
import { TranslateContext } from '../../system/Translate';
import useDeleteTemplate from './hooks/useDeleteTemplate';
import { NotificationContext } from '../Notification';
import { ConfigContext } from '../../context';

import './TemplateEditor.scss';

const DEFAULT_LANG = 'en';

export default function TemplateEditor(props) {
    /**
     * @todo
     * rethink logic for data updates sync
     * maybe with store, actions and reducers?
     */
    const { id, shopGroup } = props;

    const config = useContext(ConfigContext);
    const notify = useContext(NotificationContext);
    const history = useHistory();
    const { translate } = useContext(TranslateContext);
    const [languages, , , activeLang, setActiveLang] = useLanguages(DEFAULT_LANG);
    const [responseTemplate, getTemplate] = useApi('GET', `/template/${id}/${shopGroup}`);
    const [responseUpdate, updateTemplate] = useUpdateTemplate(id, shopGroup);
    const [template, setTemplate] = useState(null);
    const [defaultLangTemplate, setDefaultLangTemplate] = useState(null);
    const [dataToSend, setDataToSend] = useState({ shopGroup });
    const [validation, setValidation] = useState(true);
    const [supportedPlaceholders, setSupportedPlaceholders] = useState([]);
    const [selectedCase, setSelectedCase] = useState('');
    const [translationsPlaceholders, setTranslationsPlaceholders] = useState({});

    const { data: templateServer, error: getTemplateError, pending: getTemplatePending } = responseTemplate;

    const { data: deleteResponse, error, pending: deletionPending, deleteTemplate} = useDeleteTemplate(id);

    useEffect(() => {
        if (template?.automatedCase) {
            const placeholders = config ?
                config.automatedCases.reduce((acc, item) => {
                    if (item.name === template.automatedCase) {
                        return item.placeholders.map((ph) => ph.replace(/\[\[|\]\]/g, ''));
                    }
                    return acc;
                }, []) : [];

            setSupportedPlaceholders(placeholders);
        }

        if(template?.translations) {
            const processedTranslationPlaceholders =
                Object.entries(template.translations).reduce((acc, [key, value]) => {
                    if(!value?.payloadConfig?.length) {
                        return acc;
                    }
                    return {
                        ...acc,
                        [key]: value.payloadConfig.split(','),
                    }
                }, {})

            setTranslationsPlaceholders(processedTranslationPlaceholders)
        }

    }, [template, config]);

    useEffect(() => {
        if(!error && !deletionPending && deleteResponse) {
            history.push('/');
            notify('show', {
                text: 'Template creation cancelled.',
            });
        } else if(error && !deletionPending) {
            notify('show', {
                type: 'error',
                text: 'Something went wrong during template deletion. Try again later.',
            });
        }
    }, [notify, deleteResponse, error, deletionPending, history])

    const filledTranslations = Object.keys((template && template.translations) || {});
    // add default to filled 'course it's not presented in the list
    filledTranslations.push(DEFAULT_LANG);

    function getTextFor(property) {
        if (template) {
            if (activeLang === DEFAULT_LANG) {
                return defaultLangTemplate[property];
            }

            return template.translations[activeLang] ? template.translations[activeLang][property] : '';
        }
        return '';
    }

    const initialPlaceholders = useMemo(() => {
        if (template) {
            let bodyPlaceholders = [];
            let buttonPlaceholder = [];
            let headerPlaceholder = [];

            if (activeLang === DEFAULT_LANG) {
                if (template.payloadConfig?.length) {
                    bodyPlaceholders = template.payloadConfig.split(',');
                }
                if (template.buttonPlaceholder?.length) {
                    buttonPlaceholder = [template.buttonPlaceholder];
                }
                if (template.headerPlaceholder?.length) {
                    headerPlaceholder = [template.headerPlaceholder];
                }
            } else {
                if (template.translations[activeLang]?.payloadConfig) {
                    bodyPlaceholders = template.translations[activeLang]?.payloadConfig.split(',');
                }
                if (template.translations[activeLang]?.buttonPlaceholder) {
                    buttonPlaceholder = [template.translations[activeLang]?.buttonPlaceholder];
                }
                if (template.translations[activeLang]?.headerPlaceholder) {
                    headerPlaceholder = [template.translations[activeLang]?.headerPlaceholder];
                }
            }

            return {
                bodyPlaceholders,
                buttonPlaceholder,
                headerPlaceholder,
            };
        }
        return {
            bodyPlaceholders: [],
            buttonPlaceholder: [],
            headerPlaceholder: [],
        };
    }, [template, activeLang]);


    // set templates from get request
    useEffect(() => {
        if (templateServer !== null) {
            setTemplate(templateServer);
            setDefaultLangTemplate(templateServer);
        }
    }, [templateServer]);

    // set templates from update request
    useEffect(() => {
        if (responseUpdate.data !== null) {
            setTemplate(responseUpdate.data);

            // update when the default language only
            if (responseUpdate.data.locale === DEFAULT_LANG) {
                setDefaultLangTemplate(responseUpdate.data);
            }
        }
    }, [responseUpdate.data]);

    // get templates on mount component
    useEffect(() => {
        getTemplate();
    }, [getTemplate]);

    // save template if changes was mage before switch language
    function changeLang(code) {
        const { subject: subjectToSend, content: contentToSend } = dataToSend;
        if (subjectToSend !== getTextFor('subject') || contentToSend !== getTextFor('content')) {
            onSave();
        }
        setActiveLang(code);
        setValidation(true);
    }

    // prepare data to send with template changes
    const onUpdate = useCallback(
        (subject, content, placeholders) => {
            const data = { subject, content, shopGroup: dataToSend.shopGroup };
            if(placeholders) {
                Object.entries(placeholders).forEach(([key, value]) => {
                    if(typeof value === 'string') {
                        data[key] = value;
                    }
                })
            }

            setDataToSend(data);
        },
        [setDataToSend, dataToSend.shopGroup]
    );

    function onSave() {
        const templateShift = { ...template, translations: {} };
        const templateToSend = {
            ...templateShift,
            ...dataToSend,
            locale: activeLang,
        };
        const isValid = validate(templateToSend, validationRules, supportedPlaceholders);
        if (isValid === true) {
            updateTemplate({
                ...templateShift,
                ...dataToSend,
                locale: activeLang,
            });
        }
        setValidation(isValid);
    }

    const onDelete = () => {
        deleteTemplate();
    }

    const onAssign = useCallback(
        (data) => {
            setTemplate({ ...template, ...data });
        },
        [template]
    );

    return (
        <div className="nc-template-editor">
            {getTemplatePending && <FormSkeleton />}
            {getTemplateError && `${translate('template-not-loaded')}: ${getTemplateError.toString()}`}
            {template !== null && (
                <>
                    <Grid>
                        <GridCol size={7}>
                            <TextEditor
                                templateId={template.id}
                                languages={languages || []}
                                setActiveLang={changeLang}
                                subject={getTextFor('subject')}
                                content={getTextFor('content')}
                                onSave={onSave}
                                onUpdate={onUpdate}
                                onDelete={onDelete}
                                deletionPending={deletionPending}
                                pending={responseUpdate.pending}
                                activeLang={activeLang}
                                assignedCase={template.automatedCase || selectedCase}
                                subjectError={validation === true ? null : validation.subject?.join('; ')}
                                contentError={validation === true ? null : validation.content?.join('; ')}
                                channel={template.channel}
                                initialPlaceholders={initialPlaceholders}
                            />
                            <CaseSelector
                                assignedCase={template.automatedCase}
                                selectedPlaceholders={dataToSend.payloadConfig || null}
                                channel={template.channel}
                                shopGroup={template.shopGroup}
                                onAssign={onAssign}
                                onCaseSelect={(e) => setSelectedCase(e.target.value)}
                                translationPlaceholders={translationsPlaceholders}
                                id={id}
                            />
                        </GridCol>
                        <GridCol size={5}>
                            <LanguageSwitcher
                                list={languages}
                                onSelect={changeLang}
                                activeLang={activeLang}
                                filled={filledTranslations}
                            />
                        </GridCol>
                    </Grid>
                </>
            )}
        </div>
    );
}
TemplateEditor.propTypes = { id: PropTypes.string.isRequired, shopGroup: PropTypes.string };
TemplateEditor.defaultProps = { shopGroup: 'FLIX' };
