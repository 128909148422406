import {createContext} from 'react';

/**
 * RollbarContext will provide API to log 
 * application with Rollbar service 
 * @var RollbarContext
 */
const RollbarContext = createContext();

export default RollbarContext;
