import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Button,
    DataTable,
    DataTableHead,
    DataTableRow,
    Grid,
    GridCol,
    Heading,
    PageContainer,
    Select,
    SelectOption,
    Spinner,
} from '@flixbus/honeycomb-react';
import useGetOrdersData from '../../../hooks/useGetOrdersData';
import useSendWhatsappTemplate from '../../../hooks/useSendWhatsappTemplate';
import { NotificationContext } from '../../Notification';
import useGetWhatsAppTemplates from '../../../hooks/useGetWhatsAppTemplates';
import { TranslateContext } from '../../../system/Translate';
import useWhatsAppTemplateSentPopup from '../../../hooks/useWhatsAppTemplateSentPopup';

const SendWhatsAppTemplateSection = () => {
    const history = useHistory();
    const { translate } = useContext(TranslateContext);
    const notify = useContext(NotificationContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const dataToken = queryParams.get('token');
    const [templateId, setTemplateId] = useState(null);
    const { sendTemplate, response, error, loading } = useSendWhatsappTemplate();
    const { orderIds, rideId } = useGetOrdersData(dataToken);
    const { templates } = useGetWhatsAppTemplates();
    const { openPopup, WhatsAppTemplateSentPopup } = useWhatsAppTemplateSentPopup(orderIds);

    useEffect(() => {
        if(templates?.length) {
            setTemplateId(templates[0].id)
        }
    }, [templates])

    if(!dataToken) {
        history.push('/');
    }

    const headers = (
        <DataTableHead>
            <td>{translate('order_id')}</td>
        </DataTableHead>
    )

    const handleSendTemplateClick = () => {
        sendTemplate({ dataToken, templateId })
    }

    useEffect(() => {
        if(response) {
            openPopup(response.requests);
        }

        if(error) {
            notify('show', {
                type: 'warning',
                text: 'Error happened during sending template. Please, try again.',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, error])

    return (
        <>
            <PageContainer>
                <Grid extraClasses="table-grid-wrapper" justify="right">
                    <GridCol size={6}>
                        <div className="cn-send_template-wrapper">
                            {templates?.length ? (
                                <>
                                    <Select
                                        id="select_template"
                                        label={translate('template_name')}
                                        onChange={({ target }) => setTemplateId(target.value)}
                                    >
                                        {templates?.map(({ id, shortDescription }) => (
                                            <SelectOption key={id} value={id}>{shortDescription}</SelectOption>
                                        ))}
                                    </Select>
                                    <Button
                                        disabled={!templateId || loading}
                                        loading={loading}
                                        aria-label="send-template-button"
                                        extraClasses="cn-send_template"
                                        appearance="primary"
                                        onClick={handleSendTemplateClick}
                                    >
                                        {translate('send_template')}
                                    </Button>
                                </>
                            ) : (
                                <Spinner size="xl" />
                            )}

                        </div>
                    </GridCol>
                    <GridCol size={6}>
                        <Heading size={3}>
                            {translate('ride_id')}
                            :
                            {' '}
                            {rideId}
                        </Heading>
                        {orderIds?.length ? (
                            <DataTable
                                small
                                headers={headers}
                            >
                                {orderIds.map((order) => {
                                    return (
                                        <DataTableRow key={order}>
                                            <th>
                                                {order}
                                            </th>
                                        </DataTableRow>
                                    )
                                })}
                            </DataTable>
                        ) : (
                            <Spinner size="xl" />
                        )}
                    </GridCol>
                </Grid>
            </PageContainer>
            <WhatsAppTemplateSentPopup />
        </>
    )
}

export default SendWhatsAppTemplateSection;