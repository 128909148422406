import { useQuery } from 'react-query';
import api from './client';

export default function useTemplate(config) {
    const query = useQuery(
        ['template'],
        () => {
            return api.get('/template').then((response) => response.data);
        },
        config
    );
    return query;
}
