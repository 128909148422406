import React from 'react';
import RollbarContext from '../context';
import rollbar from '../Rollbar';

export default function withRollbarProvider(Component) {
    return function WithRollbarProvider(props) {
        return (
            <RollbarContext.Provider value={rollbar}>
                <Component {...props} />
            </RollbarContext.Provider>
        );
    };
}
