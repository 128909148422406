import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Tag, Grid, GridCol, Heading } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../../system/Translate';

export default function LanguageSwitcher(props) {
    const { list, onSelect, activeLang, filled } = props;
    const { translate } = useContext(TranslateContext);

    function setAppearance(langCode) {
        if (langCode === activeLang) {
            return 'active';
        }
        if (filled.indexOf(langCode) !== -1) {
            return 'filled';
        }
        return null;
    }
    return (
        <Grid>
            <GridCol size={10} push={1}>
                <div className="cn-lang-switcher">
                    <Heading size={4} sectionHeader>
                        {translate('languages')}
                    </Heading>
                    <div className="cn-lang-switcher__list">
                        {list === null
                            ? null
                            : list.map((lang) => (
                                <Tag
                                    Elem="button"
                                    type="button"
                                    appearance={
                                        setAppearance(lang.code) !== null
                                            ? 'success'
                                            : 'neutral'
                                    }
                                    onClick={() => onSelect(lang.code)}
                                    extraClasses="cn-lang-tag__button"
                                    key={lang.id}
                                    display={(setAppearance(lang.code) === 'filled') ? 'outlined' : 'solid'}
                                >
                                    {lang.code}
                                </Tag>
                            ))}
                    </div>
                </div>
            </GridCol>
        </Grid>
    );
}
LanguageSwitcher.propTypes = {
    /* list of all languages */
    list: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string,
            name: PropTypes.string,
            id: PropTypes.number,
        })
    ),
    /* selected language */
    activeLang: PropTypes.string,
    /* callback on select (to set selected) */
    onSelect: PropTypes.func,
    /* array of codes to mark those languages */
    filled: PropTypes.arrayOf(PropTypes.string),
};

LanguageSwitcher.defaultProps = {
    list: [{ code: '', name: '', id: '' }],
    activeLang: 'en',
    onSelect: () => () => {},
    filled: [],
};
