import useUserCan from './useUserCan';

const UserCan = (props) => (useUserCan(props.perform) ? props.yes() : props.no());

UserCan.defaultProps = {
    yes: () => null,
    no: () => null,
};

export default UserCan;
