import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text } from '@flixbus/honeycomb-react';
import {TranslateContext} from '../../system/Translate';

export default function DisplayTemplate({ template, raw }) {
    const { translate } = useContext(TranslateContext);
    const { content, shopGroup, subject, automatedCase } = template;
    return (
        <div>
            <Heading size={4}>{shopGroup}</Heading>
            {subject ? <Text>{subject}</Text> : null}
            <Box>
                {/* eslint-disable-next-line */}
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </Box>
            {automatedCase ? (
                <Text>
                    <strong>
                        {translate('automated-case')}
                        :
                    </strong>
                    {automatedCase}
                </Text>
            ) : null}
        </div>
    );
}
DisplayTemplate.propTypes = {
    template: PropTypes.shape().isRequired,
    raw: PropTypes.bool,
};
DisplayTemplate.defaultProps = { raw: false };
