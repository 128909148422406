import { getUser } from './index';
import { USER_RULES } from './userRules';

export default function (perform) {
    const check = (action) => {
        const roles = getUser() && getUser().profile && getUser().profile.roles;
        const permissions = [];

        if (!roles) {
            // user has no role
            return false;
        }

        roles.forEach((role) => {
            const permPerRole = USER_RULES[role] || []; // use RO permissions if such role does'n exists in a list

            permPerRole.forEach((perm) => {
                if (permissions.indexOf(perm) === -1) {
                    permissions.push(perm);
                }
            });
        });

        return !!(permissions && permissions.length && permissions.includes(action));
    };

    return check(perform);
}
