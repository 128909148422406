import React, { useContext } from 'react';
import { Tag } from '@flixbus/honeycomb-react';
import { Icon, IconClose } from '@flixbus/honeycomb-icons-react';
import { useCloudFilters } from '../App/context/CloudFiltersContext';
import { TranslateContext } from '../../system/Translate';

const CloudFilters = () => {
    const { translate } = useContext(TranslateContext);
    const { filters, removeFilter } = useCloudFilters();

    const getFilterTags = () => {
        return Object.entries(filters)
            .map(([filterName, filterValues]) => {
                return [...filterValues].map((value) => {
                    return value ? (
                        <Tag
                            type="button"
                            Elem="button"
                            display="outlined"
                            style={{ marginRight: '10px', marginBottom: '10px' }}
                            key={`${filterName}${value}`}
                            onClick={() => removeFilter(filterName, value)}
                        >
                            <strong>{translate(filterName)}</strong>
                            :
                            &nbsp;
                            {value}
                            <Icon InlineIcon={IconClose} />
                        </Tag>
                    ) : null;
                })
            })
    }

    return (
        <>{getFilterTags()}</>
    )
}

export default CloudFilters;