import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete,
    AutocompleteInput,
    AutocompleteOptions,
    AutocompleteSelectedOptions,
} from '@flixbus/honeycomb-react';

const ListPicker = ({ options, label, id, placeholder, onSelect, shouldResetValue }) => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);

    const filterAutocompleteData = (searchQuery, dataToFilter) => {
        return dataToFilter
            .filter((item) => item.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((item) => ({ title: item }));
    };

    return (
        <Autocomplete
            value={value}
            onChange={() => {
                setLoading(true)
            }}
            onDebounce={(e) => {
                setLoading(true);
                const filteredData = filterAutocompleteData(e.target.value, options)
                setData(filteredData)
                setLoading(false)
            }}
            onSelect={(item) => {
                onSelect(item.title)
                if(shouldResetValue) {
                    setValue('');
                    setData([])
                    return;
                }
                setValue(item.title)
            }}
            options={data}
            onReset={() => setValue('')}
        >
            <AutocompleteInput
                id={id}
                placeholder={placeholder}
                label={label}
                loading={loading}
                type="search"
            />
            <AutocompleteOptions
                label={label}
                optionsToDisplay={4}
                optionHasSubtitle
            />
            <AutocompleteSelectedOptions
                id="clear"
                deleteAllItemsLabel="Clear selection"
                deleteItemLabel="Remove item from selection"
            />
        </Autocomplete>
    )
}

ListPicker.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func,
    shouldResetValue: PropTypes.bool,

};
ListPicker.defaultProps = {
    options: [],
    label: '',
    id: '',
    placeholder: '',
    onSelect: () => {},
    shouldResetValue: false,
};

export default ListPicker;