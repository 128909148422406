import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import api from './client';

const BASE_URL = '/api';

function createResponse(object = {}) {
    return {
        data: null,
        pending: false,
        error: false,
        ...object,
    };
}

/**
 * Hook to use Api request with
 * Note: this hook already handle request cancellation with effect
 * @func useApi
 * @param {string} method - literal methods `post`, `get` etc.
 * @param {string|function} url - endpoint, related to apiUrl in client options, to request
 * @param {object} [customClient] - data to send in post body or get params
 * @returns {array} - [response object, requestCaller, resetResponse]
 */
export default function useApi(method, url, customClient) {
    const [response, setResponse] = useState(createResponse());
    const client = customClient || api;
    const { source } = axios.CancelToken;

    // requests requires JWT authentication

    useEffect(() => {
        return () => {
            if (typeof source.cancel === 'function') {
                source.cancel();
            }
        };
    }, [source]);

    /**
     * memoized function that will call request
     * @name requestCaller
     * @param {...object} args - arguments that will be applied to the request call
     * data, params, options, etc...
     */
    const requestCaller = useCallback(
        (data, options = {}) => {
            const config = {
                method,
                url: typeof url === 'function' ? url() : url,
                baseURL: BASE_URL,
                cancelToken: source.token,
            };

            setResponse(createResponse({ pending: true }));

            return client({ ...config, ...options, data })
                .then((response) => {
                    setResponse(createResponse({ data: response.data, pending: false }));
                    return response.data;
                })
                .catch((e) => {
                    if (!axios.isCancel(e)) {
                        setResponse(createResponse({ error: e, pending: false }));
                    }
                });
        },
        [client, url, method, source.token]
    );

    function resetResponse() {
        setResponse(createResponse({}));
    }

    return [response, requestCaller, resetResponse];
}
