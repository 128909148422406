import axios from 'axios';
import { getToken } from '../auth';

const BASE_URL = '/api';

const client = axios.create({ baseURL: BASE_URL });

client.interceptors.request.use(
    async (config) => {
        const requestConfig = { headers: {}, ...config };
        // get valid token before make request
        const token = await getToken().catch((e) => {
            throw new Error(`Authorization error: ${e}`);
        });

        if (token) {
            // set header with token
            requestConfig.headers.Authorization = `Bearer ${token}`;
        }
        return requestConfig;
    },
    (error) => Promise.reject(error)
);

client.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error.message ||
            (error.response && error.response.status !== 401) ||
            (error.config && error.config.retry)
        ) {
            throw error;
        }
        // if response fail with authentication new token will be requested
        // and retry request
        await getToken().catch((e) => {
            throw new Error(`Authorization error: ${e}`);
        });
        const newRequest = {
            ...error.config,
            retry: true,
        };
        return client(newRequest);
    }
);

export default client;
