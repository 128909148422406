export const USER_RULES = {
    cc_agent_internal: [],
    cc_agent_external: [],
    tc_agent_internal: [],
    shop_station_employee: [],
    admin: ['edit_automation_case', 'edit_template', 'add_template', 'remove_template'], // all possible permissions should be mentioned here
    editor: ['edit_template', 'add_template', 'remove_template'],
    read_only: [],
};

export const USER_ROLE_TITLES = {
    cc_agent_internal: 'CC agent int',
    cc_agent_external: 'CC agent ext',
    tc_agent_internal: 'TC agent',
    shop_station_employee: 'Shop Station Employee',
    admin: 'Admin',
    editor: 'Editor',
    read_only: 'Read Only',
};

export const USER_ACTION_TITLES = {
    'edit_automation_case': 'Edit automated case',
    'edit_template': 'Edit Template',
    'add_template': 'Add Template',
    'remove_template': 'Remove Template',
};
