import { useCallback, useEffect, useState } from 'react';
import { useApi } from '.';

/**
 * hook to control fetch and set languages
 * @name useLanguages
 * @param {string} defaultLanguage code for default language that will be set as active
 * @returns {array} - data, error, pending, active, setActiveCallback
 */

export default function useLanguages(defaultLanguage) {
    const [{ data, error, pending }, request] = useApi('GET', '/languages');
    const [active, setActive] = useState(defaultLanguage);

    useEffect(() => {
        request();
    }, [request]);

    const setActiveCallback = useCallback(
        (langCode) => {
            setActive(langCode);
        },
        [setActive]
    );

    return [data, error, pending, active, setActiveCallback];
}
